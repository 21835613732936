.card {
    display: grid;
    grid-template-columns: 12% 26% 26% 12% 12% 12%;
    grid-template-rows: 1fr;
    padding: 16px 36px;

    border-bottom: 2px solid #CDCDCD;
    font-size: 15px;
}

.card div {
    overflow-x: scroll;
    display: flex;
    align-items: center;
}

.status {
    padding: 8px;
    border-radius: 10px;
    width: fit-content;
    min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #121317;
    font-weight: 600;
    text-transform: uppercase;
    cursor: default;
}

.button {
    padding: 8px 12px;
    border-radius: 10px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #121317;
    font-weight: 600;
    cursor: pointer;
    background-color: #62A1DC;
    border: solid #2F6394 2px;
    text-transform: uppercase;
    margin: 0 4px;
}

.button.active {
    width: 100%;
}

.on {
    background-color: #AAD6A9;
    border: solid #729F71 2px;
}

.date {
    font-size: 14px;
    text-align: end;
    width: 100%;
}

.actions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}