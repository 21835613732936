.main {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
}

.balance {
    margin-bottom: 36px;
    display: flex;
    gap: 16px;
}

.balance svg * {
    fill: #fff;
    transition: 0.4s ease;  
}

.menu {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.item {
    display: flex;
    gap: 16px;

    cursor: pointer;
    transition: 0.4s ease;  

    text-decoration: none;
    color: #fff;
}

.item svg * {
    fill: #fff;
    transition: 0.4s ease;  
}

.item:hover {
    color: #62A1DC;
}

.item:hover svg * {
    fill: #62A1DC;  
}

.logout {
    margin-top: 36px;
}