.main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
}

.form {
    min-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.button {
    padding: 8px 12px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    background-color: #62A1DC;
    border: solid #2F6394 2px;
    text-transform: uppercase;
}