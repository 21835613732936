.main {
    display: grid;
    grid-template-columns: 8% 20% 12% 12% 20% 10% 10% 9%;
    grid-template-rows: 1fr;
    padding: 16px 36px;

    border-bottom: 2px solid #CDCDCD;
    font-size: 15px;
}

.input {
    padding: 6px;
    margin: 0;
    margin-right: 4px;
    font-size: 14px;
}

.forse_button {
    padding: 0;
}

.main > div {
    /* overflow-x: scroll; +6 */
    display: flex;
    align-items: center;
    font-size: 14px;
}

.status {
    padding: 8px;
    border-radius: 10px;
    width: fit-content;
    min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #121317;
    font-weight: 600;
    text-transform: uppercase;
    cursor: default;
}

.button {
    padding: 8px 12px;
    border-radius: 10px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #121317;
    font-weight: 600;
    cursor: pointer;
    background-color: #62A1DC;
    border: solid #2F6394 2px;
    text-transform: uppercase;
    margin: 0 4px;
}

.button.active {
    width: 100%;
}

.date {
    font-size: 14px;
    text-align: end;
    width: 100%;
    justify-content: end;
}

.iban {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    text-align: left;
}

.actions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}