.wrap {
    width: 100%;
}

.switch {
    width: fit-content;
    border-radius: 8px;
    border: 1.5px solid #2F6394;
    background-color: #2F6394;
    gap: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    margin-bottom: 8px;
    margin-top: 12px;
}

.load {
    padding: 16px 16px;
    background-color: #F4F4F4;
    border-radius: 8px;
    border: 2px solid #CDCDCD;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    font-size: 18px;
    background-color: #AAD6A9;
    border-color: #729F71;
}

.button {
    padding: 4px 8px;
    color: #2F6394;
    background-color: #fff;
    cursor: pointer;
}

.button.active {
    color: #fff;
    background-color: #62A1DC;
}

.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 48px;
    border-radius: 12px;
    border: 2px solid #CDCDCD;
    background-color: #27262e;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
}

.view {
    max-width: 460px;
}

.flex {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}


@media screen and (max-width: 1200px) {
    .flex {
        flex-direction: column-reverse;
    }
}