.main {
    width: 100%;
    position: relative;
}

.value {
    border: 2px solid #CDCDCD;
    padding: 12px 16px;
    border-radius: 10px;    
    font-size: 14px;
    font-weight: 600;
    user-select: none;
    cursor: pointer;
}

.list {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 8px);
    background-color: #27262e;
    border: 2px solid #CDCDCD;
    border-radius: 10px;
    display: none;
    overflow: hidden;
    z-index: 1;

    max-height: 200px;
    overflow-y: scroll;
}

.open {
    display: block;
}

.option {
    padding: 12px 16px;
    border-bottom: 2px solid #CDCDCD;
    cursor: pointer;
    transition: 0.4s ease;
    background-color: #27262e;
}

.selected {
    background-color: #7bd579;
    color: #fff;
}


.option:last-child {
    border-bottom: none;
}

.option:hover {
    background-color: #AAD6A9;
    color: #fff;
}