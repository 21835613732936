* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

body {
  width: 100vw;
  min-height: 100vh;

  overflow-x: hidden;

  font-family: sans-serif;
  background-color: #1F1E2A;
  color: #fff;
}

.app {
  width: 100vw;
  height: 100vh;

  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.app-wrap {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: auto;
  gap: 64px;
  width: 100vw;
  max-width: 100vw;
  overflow-x: scroll;
  max-height: 100%;
  height: 100%;

  overflow-y: visible;
}

.app-main {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  overflow-y: scroll;
}

.table {
  width: 100%;
}

.card {
  padding: 24px;
  border: #CDCDCD solid 2px;
  border-radius: 16px;
}


.button {
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background-color: #62A1DC;
  border: 1.5px solid #2F6394;

  color: #f2f2f2;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;

  cursor: pointer;

  outline: none;
}

.report {
  padding: 8px 12px;
  display: inline-block;
  text-decoration: none;
  color: #FFF;
  border-radius: 7px;
  border: 2px #66539D solid;
  background: #A68CEF;
  margin: 16px 0;
  cursor: pointer;
}


.active {
  color: #62A1DC !important;
}

.active svg *{
  fill: #62A1DC !important;
}


.WAIT {
  background: #F3E1B1;
}

.CONFIRM {
  background: #AAD6A9;
}

.REJECT {
  background: #E25D4A;
  color: #FFF;
}

.error {
  background: #F78faf;
}

.message {
  background: #6fc78f;
}

.hide {
  display: none !important;
}

.d-flex {
  display: flex;
  align-items: center;
  gap: 16px;
}

.w-100 {
  width: 100%;
  max-width: 100%;
}

.b-scroll {
  max-height: 100%;
  overflow: scroll;
}

.f-wrap {
  flex-wrap: wrap;
}

@media only screen and (max-width: 450px) {
  .app-wrap {
    gap: 16px;
  }
  
}