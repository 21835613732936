.main {
    border-radius: 12px;
    border: 2px solid #CDCDCD;
    max-height: 100%;

    display: flex;
    flex-direction: column;

    background-color: #27262e;
}

.top {
    display: flex;
    padding: 12px 36px;
    border-bottom: 2px solid #CDCDCD;
    gap: 24px;
}

.table {
    min-width: 100%;
    width: 1000px;
    overflow-y: scroll;
    max-height: calc(100vh - 300px);
    min-height: 400px;
}

.info {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr 3fr 3fr 1fr;
    grid-template-rows: 1fr;
    font-weight: 600;
    color: #161817;
    padding: 16px 36px;
    color: #CDCDCD;

    border-bottom: 2px solid #CDCDCD;
}

.status {
    text-align: center;
}

.date {
    text-align: end;
}

.bottom {
    padding: 16px 36px;
    display: flex;
    align-self: auto;
    justify-content: flex-end;
    gap: 18px;
}

.button {
    background-color: #62A1DC;
    border: 2px solid #2F6394;
    border-radius: 8px;
    padding: 8px 16px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

.diactive {
    background-color: #545454;
    border: 2px solid #CDCDCD;
    cursor: default;
}