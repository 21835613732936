.main {
    min-width: 350px;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    gap: 24px;
}

.title {
    font-size: 16px;
}