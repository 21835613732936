.filter {
    position: relative;
}

.filter__button {
    background-color: #62A1DC;
    border: 2px solid #2F6394;
    border-radius: 8px;
    padding: 16px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
}

.filter__option {
    min-width: 300px;
    position: absolute;
    top: calc(100% + 8px);
    left: 0;

    border: 2px solid #CDCDCD;
    background-color: #27262e;
    border-radius: 12px;
    box-shadow: 6px 6px 16px -1px #313232;

    display: none;
}

.filter__option.active {
    display: block;
}

.filter__top {
    padding: 16px;
    color: #fff;
    font-weight: 600;
}

.filter__row {
    border-top: 2px solid #CDCDCD;
    padding: 16px;
    color: #CDCDCD;
}

.filter__title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter__label {
    font-weight: 600;
    font-size: 18px;
    color: #CDCDCD;
}

.filter__icon {
    width: 16px;
    height: 16px;
    transform: translateY(1px);
    cursor: pointer;
}

.active-row .filter__icon {
    transform: translateY(1px) rotate(90deg);
}

.filter__content {
    display: none;
    margin-top: 8px;
}

.active-row .filter__content {
    display: block;
}