.main {
    padding: 48px;
    border-radius: 12px;
    border: 2px solid #CDCDCD;
    background-color: #27262e;

}

.row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    min-width: 600px;
    margin-bottom: 24px;
}

.label {
    min-width: 100px;
}

.buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 64px;
}

.button {
    padding: 8px 20px;
    background-color: #F4F4F4;
    border-radius: 8px;
    border: 2px solid #CDCDCD;
    outline: none;
    text-decoration: none;
    cursor: pointer;
}

.button_main {
    background-color: #AAD6A9;
    border-color: #729F71;
} 