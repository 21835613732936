.button_link {
    padding: 8px 12px;
    border-radius: 10px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #121317;
    font-weight: 600;
    cursor: pointer;
    background-color: #AAD6A9;
    border: solid #729F71 2px;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 16px;
}


.button {
    background-color: #62A1DC;
    border: 2px solid #2F6394;
    border-radius: 8px;
    padding: 8px 16px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

.main {
    border-radius: 12px;
    border: 2px solid #CDCDCD;
    max-height: 100%;

    display: flex;
    flex-direction: column;

    background-color: #27262e;
}

.top {
    display: flex;
    padding: 12px 36px;
    border-bottom: 2px solid #CDCDCD;
    gap: 24px;
}

.table {
    min-width: 100%;
    width: 1000px;
    overflow-y: scroll;
    max-height: calc(100vh - 300px);
    min-height: 400px;
}

.info {
    display: grid;
    grid-template-columns: 15% 10% 15% 12% 10% 12% 10% 8% 8%; 
    grid-template-rows: 1fr;
    font-weight: 600;
    color: #161817;
    padding: 16px 36px;
    font-size: 14px;
    color: #CDCDCD;

    border-bottom: 2px solid #CDCDCD;
}


.status {
    text-align: center;
}

.date {
    text-align: end;
}

.bottom {
    padding: 16px 36px;
    display: flex;
    align-self: auto;
    justify-content: flex-end;
    gap: 18px;
}

.diactive {
    background-color: #545454;
    border: 2px solid #CDCDCD;
    cursor: default;
}

.load {
    background-color: #62A1DC;
    border: 2px solid #2F6394;
    border-radius: 8px;
    padding: 8px 16px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}